import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './hoc/Layouts/Layout';
import './App.css';
import LaunchAppOne from './Components/LaunchApp/Pages/ProjectPages/LaunchAppOne/Launch-1';
import LaunchAppTwo from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwo/Launch-2';
import LaunchAppThree from './Components/LaunchApp/Pages/ProjectPages/LaunchAppThree/Launch-3';
import LaunchAppFour from './Components/LaunchApp/Pages/ProjectPages/LaunchAppFour/Launch-4';
import LaunchAppFive from './Components/LaunchApp/Pages/ProjectPages/LaunchAppFive/Launch-5';
import LaunchAppSix from './Components/LaunchApp/Pages/ProjectPages/LaunchAppSix/Launch-6';
import LaunchAppSeven from './Components/LaunchApp/Pages/ProjectPages/LaunchAppSeven/Launch-7';
import LaunchAppEight from './Components/LaunchApp/Pages/ProjectPages/LaunchAppEight/Launch-8';
// import LaunchAppNine from './Components/LaunchApp/Pages/ProjectPages/LaunchAppNine/Launch-9';
import LaunchAppTen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTen/Launch-10';
import LaunchAppEleven from './Components/LaunchApp/Pages/ProjectPages/LaunchAppEleven/Launch-11';
import LaunchAppTwelve from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwelve/Launch-12';
import LaunchAppThirteen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppThirteen/Launch-13';
import LaunchAppFourteen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppFourteen/Launch-14';
import LaunchAppFifteen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppFifteen/Launch-15';
import LaunchAppSixteen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppSixteen/Launch-16';
import LaunchAppSeventeen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppSeventeen/Launch-17';
import LaunchAppEighteen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppEighteen/Launch-18';
import LaunchAppNinteen from './Components/LaunchApp/Pages/ProjectPages/LaunchAppNinteen/Launch-19';
import LaunchAppTwenty from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwenty/Launch-20';
import LaunchAppTwentyOne from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyOne/Launch-21';
import LaunchAppTwentyTwo from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyTwo/Launch-22';
import LaunchAppTwentyThree from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyThree/Launch-23';
import LaunchAppTwentyFour from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyFour/Launch-24';
import LaunchAppTwentyFive from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyFive/Launch-25';
import LaunchAppTwentySix from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentySix/Launch-26';
import LaunchAppTwentySeven from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentySeven/Launch-27';
import LaunchAppTwentyEight from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyEight/Launch-28';
import LaunchAppTwentyNine from './Components/LaunchApp/Pages/ProjectPages/LaunchAppTwentyNine/Launch-29';
import LaunchAppThirtyTwo from './Components/LaunchApp/Pages/ProjectPages/LaunchAppThirtyTwo/Launch-32';
import LaunchAppThirtyThree from './Components/LaunchApp/Pages/ProjectPages/LaunchAppThirtyThree/Launch-33';
import LaunchAppThirtyFour from './Components/LaunchApp/Pages/ProjectPages/LaunchAppThirtyFour/Launch-34';
const Home = lazy(() => import("./Components/Home/Home"));
const LaunchApp = lazy(() => import('./Components/LaunchApp/LaunchApp'));
// const Staking = lazy(() => import('./Components/Staking/staking'));

const App = () => {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Suspense fallback={null}>
            <Switch>
              <Route path="/" exact component={Home}></Route>
              <Route path="/launch-app" exact component={LaunchApp}></Route>
              <Route path="/launch-app/1" component={LaunchAppOne}></Route>
              <Route path="/launch-app/2" component={LaunchAppTwo}></Route>
              <Route path="/launch-app/3" component={LaunchAppThree}></Route>
              <Route path="/launch-app/4" component={LaunchAppFour}></Route>
              <Route path="/launch-app/5" component={LaunchAppFive}></Route>
              <Route path="/launch-app/6" component={LaunchAppSix}></Route>
              <Route path="/launch-app/7" component={LaunchAppSeven}></Route>
              <Route path="/launch-app/8" component={LaunchAppEight}></Route>
              <Route path="/launch-app/10" component={LaunchAppTen}></Route>
              <Route path="/launch-app/11" component={LaunchAppEleven}></Route>
              <Route path="/launch-app/12" component={LaunchAppTwelve}></Route>
              <Route path="/launch-app/13" component={LaunchAppThirteen}></Route>
              <Route path="/launch-app/14" component={LaunchAppFourteen}></Route>
              <Route path="/launch-app/15" component={LaunchAppFifteen}></Route>
              <Route path="/launch-app/16" component={LaunchAppSixteen}></Route>
              <Route path="/launch-app/17" component={LaunchAppSeventeen}></Route>
              <Route path="/launch-app/18" component={LaunchAppEighteen}></Route>
              <Route path="/launch-app/19" component={LaunchAppNinteen}></Route>
              <Route path="/launch-app/20" component={LaunchAppTwenty}></Route>
              <Route path="/launch-app/21" component={LaunchAppTwentyOne}></Route>
              <Route path="/launch-app/22" component={LaunchAppTwentyTwo}></Route>
              <Route path="/launch-app/23" component={LaunchAppTwentyThree}></Route>
              <Route path="/launch-app/24" component={LaunchAppTwentyFour}></Route>
              <Route path="/launch-app/25" component={LaunchAppTwentyFive}></Route>
              <Route path="/launch-app/27" component={LaunchAppTwentySix}></Route>
              <Route path="/launch-app/29" component={LaunchAppTwentyEight}></Route>
              <Route path="/launch-app/30" component={LaunchAppTwentyNine}></Route>
              <Route path="/launch-app/32" component={LaunchAppThirtyTwo}></Route>
              <Route path="/launch-app/33" component={LaunchAppThirtyThree}></Route>
              <Route path="/launch-app/34" component={LaunchAppThirtyFour}></Route>
              {/* <Route path="/launch-app/28" component={LaunchAppTwentySeven}></Route> */}
              {/* <Route path="/launch-app/9" component={LaunchAppNine}></Route> */}
              {/* <Route path="/staking" component={Staking}></Route> */}
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    </div>
  );
}

export default App;