export const ACCOUNT_DETAILS = "accountDetial";
export const TRANSACTION_COMPLETED = "transactionCompleted";
export const ADDRESS_WHITELISTING = "addressWhiteListing";
export const DISCONNECT_METAMASK = "disconnectMetamask";
export const CONTRIBUTION_ROUNDS = "contribution_rounds";
export const TOGGLE_MODAL = "toggleModal";
export const ROUND_DETAILS = "roundDetails";
export const CLAIM_TOKENS = "calimTokens";
export const ROUTER_VALIDATION = "routerValidations"

export const MESSAGE = "message";
export const ERROR = "error";