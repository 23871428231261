import { useState, useEffect } from "react";
import LaunchLogo from '../../../../../assets/Images/gameyoo_big.png';
import '../../../LaunchApp.css'
import { connect } from 'react-redux';
import { Progress } from 'react-sweet-progress';
import api from '../../../../../config/axios-config'
import { connectMetamask, fetchRoundDetails, sendTransaction, toggleModal, claimTokens } from '../../../../../store/actions/actions';

const LaunchAppTwentyTwo = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [solanaAddress, setSolanaAddress] = useState('');
    const [showSolanaAddressInput, setShowSolanaAddressInput] = useState(false);
    useEffect(async () => {
        if (props.connection.isConnected) {
            setSolanaAddress('')
            const value = {
                walletAddress: props.accountDetail.address,
                projectName: 'Gameyoo'
            }
            const { data } = await api.post('solanaWallet/checkIfWalletPresent', value);
            if (Object.keys(data.data).length > 0) {
                setShowSolanaAddressInput(false);
                setSolanaAddress(data.data.solanaWalletAddress)
            } else {
                console.log(data.data);
                setShowSolanaAddressInput(true);
            }
        }
    }, [props.connection.isConnected, props.accountDetail.address]);

    const addSolanaAddress = () => {
        const value = {
            walletAddress: props.accountDetail.address,
            projectName: 'Gameyoo',
            solanaWalletAddress: solanaAddress
        }
        api.post('solanaWallet/addSolanaWalletAddress', value)
            .then(data => {
                setSolanaAddress('');
                setShowSolanaAddressInput(false);
            })
    }

    const [progressPer, setProgress] = useState(0);
    useEffect(() => {
        progressBar();
        // if (progressPer === 100) { clearInterval(interval) }
        // return () => clearInterval(interval)
    }, [props.rounds.currentAvailable, seconds]);

    const progressBar = () => {
        if (props.connection.isConnected) {
            const percentage = ((Number(props.rounds.totalAvailable) - Number(props.rounds.currentAvailable).toFixed(0)) / Number(props.rounds.totalAvailable)) * 100;
            console.log(((Number(props.rounds.totalAvailable) - Number(props.rounds.currentAvailable).toFixed(0)) / Number(props.rounds.totalAvailable)) * 100);
            setProgress(Number(percentage).toFixed(0));
        }
    }

    var [seconds, setSeconds] = useState(props.rounds.timer.s);
    const [minutes, setMinutes] = useState(props.rounds.timer.m);
    const [hours, setHours] = useState(props.rounds.timer.h);
    const [currentRound, setCurrentRound] = useState(props.rounds.currentRound);
    useEffect(() => {
        if (seconds || minutes || hours > 0) {
            const interval = setInterval(() => {
                timer(interval);
            }, 1000);
            return () => clearInterval(interval)
        }
        // if (props.rounds.currentRound > props.rounds.noOfRounds || Number(progressPer) === 100) {
        //     clearInterval(interval);
        //     setHours(0);
        //     setMinutes(0);
        //     setSeconds(0);
        // };
    }, [hours, minutes, seconds, props.rounds.currentAvailable]);

    const date = new Date();

    const timer = (interval) => {
        if (seconds > 0) {
            setSeconds(seconds - 1);
        }
        if (seconds === 0) {
            if (minutes === 0) {
                if (hours === 0) {
                    clearInterval(interval);
                } else {
                    setHours(hours - 1);
                    setMinutes(59);
                }
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }
        if (props.rounds.currentRound > currentRound) {
            setCurrentRound(props.rounds.currentRound);
            setSeconds(59);
        }
    }

    useEffect(() => {
        props.onConnectToMetamask(props.networks.main.bsc, 22);
    }, []);

    useEffect(() => {
        if (props.rounds.active) {
            setHours(props.rounds.timer.h);
            setMinutes(props.rounds.timer.m);
            setSeconds(props.rounds.timer.s);
            // Moment((new Date(Number(claimDates._d4 + "000"))).toString()).format('DD-MM-YYYY')
            // const td = new Date(props.rounds.claimDates._d2).setHours(0, 0, 0, 0);
            // const bd = new Date().setHours(0, 0, 0, 0);
            // console.log(td, bd, td < bd);
        }
    }, [props.rounds.active]);

    const [inputValue, setInputValue] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [showError, setShowError] = useState('');
    const toggleInput = async () => {
        if (showInput) {
            if (Number(inputValue) >= Number(props.rounds.minAllocation)) {
                if (Number(inputValue) <= Number(props.rounds.maxAllocation)) {
                    if ((Number(inputValue) + Number(props.transactions.tokens)) < Number(props.rounds.totalAvailable)) {
                        setShowError("");
                        props.sendTransaction(Number(inputValue));
                        if (props.transactions.tokens > 0) progressBar()
                    } else {
                        setShowError("Total Allocation cannot exceed More Than Hardcap Amount")
                    }
                } else {
                    setShowError("Amount shoud be less than Max Allocation!");
                }
            } else {
                setShowError("Amount shoud be more than Min Allocation!");
            }
        } else {
            setShowInput(!showInput);
        }
    }

    const [showHash, setShowHash] = useState(false);
    useEffect(() => {
        if (props.connection.isConnected && props.transactions.isCompleted) {
            setShowHash(true);
            setInputValue(0);
            setTimeout(() => {
                setShowHash(false)
            }, 10000);
        }
    }, [props.transactions.isCompleted])

    return (
        <div className="launch-app">
            {/* <HoverMenu /> */}
            <div style={{ position: 'fixed', width: '100%', height: '100vh' }}>
                {/* <Particles /> */}
            </div>
            <div className="launch-app-cont">
                <div className="launch-app-cont-1" style={{ marginTop: '15vh' }}>
                    <div className="launch1-box-cont">
                        <div className="launch1-box">

                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={LaunchLogo} style={{ width: '55%', borderRadius: '5px' }} />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: '18px' }}>Project Name</div>
                                    <div className="launch1-sub-head">Gameyoo</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ fontSize: '18px' }}>Ticker</div>
                                    <div className="launch1-sub-head">{props.rounds.symbol}</div>
                                </div>
                            </div>

                            <div className="launch1-box-website-btn">
                                <a href="https://www.gameyoo.com/" target="_blank">WEBSITE</a>
                            </div>

                            <div style={{ height: '1px', backgroundColor: 'white', marginTop: '20px' }}></div>

                            <div className="launch1-sub-text">
                                Gameyoo is a Bridge for Traditional and Blockchain Gaming. It seeks to rebalance the industry by bringing more
                                traditional gamers into the GameFi fold.
                                We strongly believe that in the web 3.0 era, the true ownership of digital assets, tokenized gaming currency
                                and blockchain-based gaming characters will revolutionize the gaming industry. All players should have the
                                opportunity to create more value out of their achievements in games.
                                {/* Our mission is to lead more gamers to participate in blockchain games and enjoy them, and to provide a
                                full range of services to more game developers. */}

                            </div>
                        </div>
                        <div className="launch1-box">
                            <div style={{ marginTop: '20px' }}>
                                {(Number(props.rounds.totalAvailable) - Number(props.rounds.currentAvailable)).toFixed(2)} Sold of {props.rounds.totalAvailable}
                                {
                                    Number(progressPer) === 100
                                        ? <span className="cstmText"> (HardCap Reached)</span>
                                        : null
                                }
                            </div>
                            <div className="progress-bar-cont">
                                {/* <progress max="100" color="danger" value={progressPer} style={{ width: '90%' }}></progress>
                                <div >{progressPer}%</div> */}
                                <Progress
                                    percent={progressPer}
                                    theme={{
                                        success: {
                                            symbol: progressPer + '%',
                                            trailColor: 'white',
                                            color: 'springgreen'
                                        },
                                        active: {
                                            trailColor: 'white',
                                            color: 'springgreen'
                                        },
                                        default: {
                                            trailColor: 'white',
                                            color: 'springgreen'
                                        }
                                    }}
                                />
                            </div>

                            <div className="launch1-mini-box" style={{ marginTop: '40px' }}>
                                <div className="launch1-mini-1">
                                    <div >Round</div>
                                    <div style={{ color: 'wheat' }}>
                                        {Number(progressPer) === 100
                                            || (
                                                Number(props.rounds.currentRound) === 2 &&
                                                (hours <= 0 &
                                                    minutes <= 0 &
                                                    seconds <= 0)
                                            )
                                            ? 'Sale Closed'
                                            :
                                            (
                                                hours > 0 ||
                                                minutes > 0 ||
                                                seconds > 0
                                            )
                                                ?
                                                props.rounds.currentRound :
                                                props.rounds.currentRound + ' Closed'
                                        }
                                    </div>
                                </div>
                                <div className="launch1-mini-1">
                                    <div className="">Time Remaining</div>
                                    <div style={{ color: 'wheat' }}>
                                        {
                                            (hours > 0 || minutes > 0 || seconds > 0) && Number(props.rounds.startTime) * 1000 < Date.now() ?
                                                hours + 'h' + ' : ' + minutes + 'm' + ' : ' + seconds + 's'
                                                : Number(props.rounds.startTime) * 1000 > Date.now() ? 'Not Yet Started' : '00:00:00'
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="launch1-mini-box">
                                <div className="launch1-mini-1">
                                    <div style={{ textAlign: 'center' }}>Min Contribution</div>
                                    <div style={{ color: 'wheat' }}>
                                        {Number(props.rounds.minAllocation).toFixed(2)} {sessionStorage.networkConnected ? JSON.parse(sessionStorage.networkConnected).tokenName : 'TBA'}
                                    </div>
                                </div>
                                <div className="launch1-mini-1">
                                    <div className="">Max Contribution</div>
                                    <div style={{ color: 'wheat' }}>
                                        {Number(props.rounds.maxAllocation).toFixed(2)} {sessionStorage.networkConnected ? JSON.parse(sessionStorage.networkConnected).tokenName : 'TBA'}
                                    </div>
                                </div>
                            </div>
                            <div className="launch1-mini-box">
                                <div className="launch1-mini-1">
                                    <div>{sessionStorage.networkConnected ? JSON.parse(sessionStorage.networkConnected).tokenName : 'TBA'} Contributed</div>
                                    <div style={{ color: 'wheat' }}>{Number(props.rounds.userAmountPurchased._contribution).toFixed(2)} {sessionStorage.networkConnected ? JSON.parse(sessionStorage.networkConnected).tokenName : 'TBA'}</div>
                                </div>
                                <div className="launch1-mini-1">
                                    <div className="">{props.rounds.symbol} Allocated</div>
                                    <div style={{ color: 'wheat' }}>{Number(props.rounds.userAmountPurchased._allocation).toFixed(2)} {props.rounds.symbol}</div>
                                </div>
                            </div>
                            {
                                showHash ?
                                    <>
                                        <div className="transactionHash">
                                            <div className="hash-box">
                                                <p className="m-0" style={{ 'fontSize': '14px' }}>Transaction Hash</p>
                                                <p className="m-0 cstmText sm-Text">
                                                    <a style={{ 'wordWrap': 'break-word' }} href={`https://bscscan.com/tx/${props.transactions.hash.transactionHash}`} target="_blank">
                                                        {props.transactions.hash.transactionHash}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                    : null
                            }
                            <div className="launch1-box-button">
                                <div className="mb-2" style={{ width: '92%', position: 'relative' }}>
                                    {
                                        showInput && Number(progressPer) < 100
                                            ?
                                            <>
                                                <p className="tokenPrice">1 {props.rounds.symbol} = {props.rounds.tokenPrice} BUSD</p>
                                                <input
                                                    type="number"
                                                    className="form-control cstmInput"
                                                    onChange={(e) => { setInputValue(e.target.value); setShowError(""); }}
                                                    placeholder="Enter Contribution Amount"
                                                    value={inputValue}
                                                    autoFocus
                                                >
                                                </input>
                                                <button
                                                    className="cstmBtn maxBtn"
                                                    onClick={() => { setInputValue(Number(props.rounds.maxAllocation) - 0.000001) }}>Max</button>
                                            </>
                                            : null
                                    }
                                    {
                                        showError !== ''
                                            ?
                                            <p className="errorMsg mb-0">{showError}</p>
                                            : null
                                    }
                                </div>
                                <button
                                    type="button"
                                    className={
                                        props.connection.isConnected &&
                                            ((props.rounds.isAddressWhiteListedAllowed
                                                && props.accountDetail.isAddressWhiteListed)
                                                || !props.rounds.isAddressWhiteListedAllowed) &&
                                            (Number(progressPer) < 100) &&
                                            Number(props.rounds.startTime) * 1000 < Date.now() &&
                                            (
                                                hours > 0 ||
                                                minutes > 0 ||
                                                seconds > 0
                                            )
                                            // &&
                                            // (Number(props.rounds.userAmountPurchased._contribution).toFixed(6) < Number(props.rounds.maxAllocation).toFixed(6)) &&
                                            // (inputValue < (Number(props.rounds.maxAllocation).toFixed(6) - Number(props.rounds.userAmountPurchased._contribution).toFixed(6)))
                                            ? 'cstmBtn' : 'cstmBtn isDisabled'
                                    }
                                    disabled={
                                        !props.connection.isConnected ||
                                        (props.rounds.isAddressWhiteListedAllowed
                                            && !props.accountDetail.isAddressWhiteListed) ||
                                        (Number(progressPer) === 100) ||
                                        Number(props.rounds.startTime) * 1000 > Date.now() ||
                                        (
                                            hours <= 0 &&
                                            minutes <= 0 &&
                                            seconds <= 0
                                        )
                                        // ||
                                        // (Number(props.rounds.userAmountPurchased._contribution).toFixed(6) >= Number(props.rounds.maxAllocation).toFixed(6)) ||
                                        // (inputValue > (Number(props.rounds.maxAllocation).toFixed(6) - Number(props.rounds.userAmountPurchased._contribution).toFixed(6)))
                                    }
                                    onClick={() => toggleInput()}
                                >Contribute
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-cont">
                        <div className="launch1-box-button" style={{ flexDirection: 'row', marginBottom: '10px' }}>
                            <div style={{ width: '60%' }}>
                                <input
                                    type="text"
                                    className="form-control cstmInput"
                                    onChange={(e) => { setSolanaAddress(e.target.value) }}
                                    placeholder="Enter Solana Wallet Address"
                                    value={solanaAddress}
                                    autoFocus
                                    readOnly={!showSolanaAddressInput}
                                >
                                </input>
                                {
                                    !showSolanaAddressInput ? <span style={{ color: 'wheat', display: 'block' }}>Solana Address Added</span> : null
                                }
                                {
                                    showSolanaAddressInput && solanaAddress == '' ? <span style={{ color: 'wheat', display: 'block' }}>Please Enter A Solana Address</span> : null
                                }
                            </div>
                            <div style={{ width: '30%', marginLeft: '15px' }}>
                                <button
                                    type="button"
                                    className={
                                        showSolanaAddressInput && solanaAddress != '' ? 'cstmBtn' : 'cstmBtn isDisabled'
                                    }
                                    disabled={!showSolanaAddressInput || solanaAddress == ''}
                                    onChange={(e) => { setSolanaAddress(e.target.value) }}
                                    onClick={() => { addSolanaAddress() }}
                                >Save</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        accountDetail: state.reducer.accountDetails || {},
        connection: state.reducer.connection || {},
        rounds: state.reducer.rounds || {},
        transactions: state.reducer.transactions || {},
        networks: state.reducer.networks || {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendTransaction: (inputValue) => dispatch(sendTransaction(inputValue)),
        fetchRoundDetails: () => dispatch(fetchRoundDetails()),
        toggleModal: () => dispatch(toggleModal()),
        claimTokens: (val) => dispatch(claimTokens(val)),
        onConnectToMetamask: (network, projectNo) => dispatch(connectMetamask(network, projectNo))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaunchAppTwentyTwo);