import { Component } from "react";
import Aux from "../Auxiliary/Auxiliary";
import Navbar from '../../Components/Navbar/Navbar';
class Layout extends Component {
    render() {
        return (
            <Aux>
                <Navbar></Navbar>
                <main>
                    {this.props.children}
                </main>
            </Aux>
        )
    }
}
export default Layout;