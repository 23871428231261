import * as actions from '../actions/actionTypes';

const initialState = {
    networks: {
        main: {
            bsc: { name: 'BSC Mainnet', chainId: 56, tokenName: 'USDT' },
            polygon: { name: 'POLYGON Mainnet', chainId: 137, tokenName: 'USDT' },
            avalanche: { name: 'Avalanche Mainnet', chainId: 43114, tokenName: 'AVAX' },
            ethereum: { name: 'Ethereum Mainnet', chainId: 1, tokenName: 'ETH' }
        },
        testnet: {
            bsc: { name: 'BSC Testnet', chainId: 97, tokenName: 'USDT' },
            polygon: { name: 'POLYGON Testnet', chainId: 80001, tokenName: 'USDT' },
            avalanche: { name: 'Avalanche FUJI', chainId: 43113, tokenName: 'AVAX' },
            ethereum: { name: 'Sepolia Test Netwok', chainId: 11155111, tokenName: 'SepoliaETH' }
        }
    },
    connection: {
        isConnected: false,
        isAddressAvailable: false,
        connectedNetwork: {
            name: '',
            chainId: 0
        }
    },
    accountDetails: {
        isAddressWhiteListed: false
    },
    notifications: {},
    transactions: {
        tokens: 0,
        isCompleted: 0,
        hash: {
            transactionHash: ''
        }
    },
    rounds: {
        active: false,
        noOfRounds: 2,
        timer: { h: 0, m: 0, s: 0 },
        currentRound: 0,
        minAllocation: 0,
        maxAllocation: 0,
        totalAvailable: 0,
        currentAvailable: 0,
        isAddressWhiteListedAllowed: false,
        startTime: 0,
        tokenPrice: 0,
        userTokenAllocations: {
            _div1: 0,
            _div2: 0,
            _div3: 0,
            _div4: 0,
            _div5: 0,
            _div6: 0,
            _div7: 0,
        },
        userAmountPurchased: {
            _allocation: 0,
            _contribution: 0
        },
        userClaimTokens: 0,
        claimTokensHistory: {
            r1: 0,
            r2: 0,
            r3: 0,
            r4: 0,
            r5: 0,
            r6: 0,
            r7: 0,
        },
        claimDates: {
            _d1: 0 / 0 / 0,
            _d2: 0 / 0 / 0,
            _d3: 0 / 0 / 0,
            _d4: 0 / 0 / 0,
            _d5: 0 / 0 / 0,
            _d6: 0 / 0 / 0,
            _d7: 0 / 0 / 0,
            d1: 0,
            d2: 0,
            d3: 0,
            d4: 0,
            d5: 0,
            d6: 0,
            d7: 0,
        },
        symbol: 'TBA'
    },
    modal: {
        show: false
    },
    stake: {
        viewableData: {
            totalStakedAmount: 0,
            totalStakers: 0,
            reward: 0
        }
    },
    error: "",
    router: {
        isAllowed: false
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.ACCOUNT_DETAILS:
            return {
                ...state,
                connection: {
                    ...state.connection,
                    isConnected: action.payload.data.connection.isConnected,
                    networkType: action.payload.data.connection.networkType,
                    connectedNetwork: {
                        ...state.connection.connectedNetwork,
                        name: action.payload.data.connection.connectedNetwork.name,
                        chainId: action.payload.data.connection.connectedNetwork.chainId
                    }
                },
                accountDetails: {
                    ...state.accountDetails,
                    address: action.payload.data.accountDetails.address,
                    balance: action.payload.data.accountDetails.balance
                },
                notifications: {
                    ...state.notifications,
                    message: action.payload.data.notifications.message,
                    error: action.payload.data.notifications.error
                }
            }
        case actions.ADDRESS_WHITELISTING:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    isAddressWhiteListed: action.payload.data.isAddressWhiteListed
                }
            }
        case actions.TRANSACTION_COMPLETED:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    isCompleted: state.transactions.isCompleted + 1,
                    tokens: Number(state.transactions.tokens) + Number(action.payload.data.tokens),
                    hash: {
                        ...state.transactions.hash,
                        transactionHash: action.payload.data.hash.transactionHash
                    }
                },
                rounds: {
                    ...state.rounds,
                    totalAvailable: action.payload.data.p.totalAvailable,
                    currentAvailable: action.payload.data.p.currentAvailable,
                    userAmountPurchased: {
                        ...state.rounds.userAmountPurchased,
                        _allocation: action.payload.data.u.userAmountPurchased._allocation,
                        _contribution: action.payload.data.u.userAmountPurchased._contribution
                    },
                    userTokenAllocations: {
                        ...state.rounds.userTokenAllocations,
                        _div1: action.payload.data.c.userTokenAllocations._div1,
                        _div2: action.payload.data.c.userTokenAllocations._div2,
                        _div3: action.payload.data.c.userTokenAllocations._div3,
                        _div4: action.payload.data.c.userTokenAllocations._div4,
                        _div5: action.payload.data.c.userTokenAllocations._div5,
                        _div6: action.payload.data.c.userTokenAllocations._div6,
                        _div7: action.payload.data.c.userTokenAllocations._div7,
                    },
                    claimTokensHistory: {
                        ...state.rounds.claimTokensHistory,
                        r1: action.payload.data.c.claimTokensHistory.r1,
                        r2: action.payload.data.c.claimTokensHistory.r2,
                        r3: action.payload.data.c.claimTokensHistory.r3,
                        r4: action.payload.data.c.claimTokensHistory.r4,
                        r5: action.payload.data.c.claimTokensHistory.r5,
                        r6: action.payload.data.c.claimTokensHistory.r6,
                        r7: action.payload.data.c.claimTokensHistory.r7,
                    },
                    claimDates: {
                        ...state.rounds.claimDates,
                        _d1: action.payload.data.c.claimDates._d1,
                        _d2: action.payload.data.c.claimDates._d2,
                        _d3: action.payload.data.c.claimDates._d3,
                        _d4: action.payload.data.c.claimDates._d4,
                        _d5: action.payload.data.c.claimDates._d5,
                        _d6: action.payload.data.c.claimDates._d6,
                        _d7: action.payload.data.c.claimDates._d7,
                        d1: action.payload.data.c.claimDates.d1,
                        d2: action.payload.data.c.claimDates.d2,
                        d3: action.payload.data.c.claimDates.d3,
                        d4: action.payload.data.c.claimDates.d4,
                        d5: action.payload.data.c.claimDates.d5,
                        d6: action.payload.data.c.claimDates.d6,
                        d7: action.payload.data.c.claimDates.d7,
                    }
                }
            }
        case actions.ROUND_DETAILS:
            return {
                ...state,
                rounds: {
                    ...state.rounds,
                    active: true,
                    timer: { h: action.payload.data.s.endTime.h, m: action.payload.data.s.endTime.m, s: action.payload.data.s.endTime.s },
                    startTime: action.payload.data.s.startTime,
                    currentRound: action.payload.data.s.saleId,
                    minAllocation: action.payload.data.s.minAmount,
                    maxAllocation: action.payload.data.s.maxAmount,
                    symbol: action.payload.data.s.symbol,
                    tokenPrice: action.payload.data.s.tokenPrice,
                    isAddressWhiteListedAllowed: action.payload.data.s.isAddressWhiteListedAllowed,
                    totalAvailable: action.payload.data.p.totalAvailable,
                    currentAvailable: action.payload.data.p.currentAvailable,
                    userAmountPurchased: {
                        ...state.rounds.userAmountPurchased,
                        _allocation: action.payload.data.u.userAmountPurchased._allocation,
                        _contribution: action.payload.data.u.userAmountPurchased._contribution
                    },
                    userTokenAllocations: {
                        ...state.rounds.userTokenAllocations,
                        _div1: action.payload.data.c.userTokenAllocations._div1,
                        _div2: action.payload.data.c.userTokenAllocations._div2,
                        _div3: action.payload.data.c.userTokenAllocations._div3,
                        _div4: action.payload.data.c.userTokenAllocations._div4,
                        _div5: action.payload.data.c.userTokenAllocations._div5,
                        _div6: action.payload.data.c.userTokenAllocations._div6,
                        _div7: action.payload.data.c.userTokenAllocations._div7,
                    },
                    claimTokensHistory: {
                        ...state.rounds.claimTokensHistory,
                        r1: action.payload.data.c.claimTokensHistory.r1,
                        r2: action.payload.data.c.claimTokensHistory.r2,
                        r3: action.payload.data.c.claimTokensHistory.r3,
                        r4: action.payload.data.c.claimTokensHistory.r4,
                        r5: action.payload.data.c.claimTokensHistory.r5,
                        r6: action.payload.data.c.claimTokensHistory.r6,
                        r7: action.payload.data.c.claimTokensHistory.r7,
                    },
                    claimDates: {
                        ...state.rounds.claimDates,
                        _d1: action.payload.data.c.claimDates._d1,
                        _d2: action.payload.data.c.claimDates._d2,
                        _d3: action.payload.data.c.claimDates._d3,
                        _d4: action.payload.data.c.claimDates._d4,
                        _d5: action.payload.data.c.claimDates._d5,
                        _d6: action.payload.data.c.claimDates._d6,
                        _d7: action.payload.data.c.claimDates._d7,
                        d1: action.payload.data.c.claimDates.d1,
                        d2: action.payload.data.c.claimDates.d2,
                        d3: action.payload.data.c.claimDates.d3,
                        d4: action.payload.data.c.claimDates.d4,
                        d5: action.payload.data.c.claimDates.d5,
                        d6: action.payload.data.c.claimDates.d6,
                        d7: action.payload.data.c.claimDates.d7,
                    }
                }
            }
        case actions.CLAIM_TOKENS:
            return {
                ...state,
                rounds: {
                    ...state.rounds,
                    userTokenAllocations: {
                        ...state.rounds.userTokenAllocations,
                        _div1: action.payload.data.c.userTokenAllocations._div1,
                        _div2: action.payload.data.c.userTokenAllocations._div2,
                        _div3: action.payload.data.c.userTokenAllocations._div3,
                        _div4: action.payload.data.c.userTokenAllocations._div4,
                        _div5: action.payload.data.c.userTokenAllocations._div5,
                        _div6: action.payload.data.c.userTokenAllocations._div6,
                        _div7: action.payload.data.c.userTokenAllocations._div7,
                    },
                    claimTokensHistory: {
                        ...state.rounds.claimTokensHistory,
                        r1: action.payload.data.c.claimTokensHistory.r1,
                        r2: action.payload.data.c.claimTokensHistory.r2,
                        r3: action.payload.data.c.claimTokensHistory.r3,
                        r4: action.payload.data.c.claimTokensHistory.r4,
                        r5: action.payload.data.c.claimTokensHistory.r5,
                        r6: action.payload.data.c.claimTokensHistory.r6,
                        r7: action.payload.data.c.claimTokensHistory.r7,
                    },
                    claimDates: {
                        ...state.rounds.claimDates,
                        _d1: action.payload.data.c.claimDates._d1,
                        _d2: action.payload.data.c.claimDates._d2,
                        _d3: action.payload.data.c.claimDates._d3,
                        _d4: action.payload.data.c.claimDates._d4,
                        _d5: action.payload.data.c.claimDates._d5,
                        _d6: action.payload.data.c.claimDates._d6,
                        _d7: action.payload.data.c.claimDates._d7,
                        d1: action.payload.data.c.claimDates.d1,
                        d2: action.payload.data.c.claimDates.d2,
                        d3: action.payload.data.c.claimDates.d3,
                        d4: action.payload.data.c.claimDates.d4,
                        d5: action.payload.data.c.claimDates.d5,
                        d6: action.payload.data.c.claimDates.d6,
                        d7: action.payload.data.c.claimDates.d7,
                    }
                }
            }
        case actions.DISCONNECT_METAMASK:
            return {
                ...state,
                accountDetails: {
                    ...state.accountDetails,
                    isAddressWhiteListed: false
                },
                transactions: {
                    ...state.transactions,
                    tokens: 0.00000
                },
                connection: {
                    ...state.connection,
                    isConnected: false,
                    isAddressAvailable: false,
                    connectedNetwork: {
                        ...state.connection.connectedNetwork,
                        name: '',
                        chainId: 0
                    }

                },
            }
        case actions.ROUTER_VALIDATION:
            return {
                ...state,
                router: {
                    isAllowed: action.payload.data.isAllowed
                }
            }
        case actions.TOGGLE_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    show: !state.modal.show
                }
            }
        case actions.ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        default:
            return state
    }
}