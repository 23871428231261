import * as actions from './actionTypes';
import { checkAddressWhiteListing, isMetaMaskInstalled, InitiateTransaction, roundDetails, InitateClaimTokens } from '../../web3/web3';
import { toastr } from 'react-redux-toastr';

let currentProjectNumber = 0;
export const connectMetamask = (network, projectNo) => {
    return dispatch => {
        console.log({ projectNo });
        projectNo != '' && projectNo != null && projectNo != undefined ? currentProjectNumber = projectNo : null
        console.log({ currentProjectNumber });
        return isMetaMaskInstalled(dispatch, network, currentProjectNumber)
            .then(data => {
                toastr.success("", data.notifications.message);
                return dispatch(updateAccountDetails(data));
            })
            .then(async () => {
                if (currentProjectNumber && currentProjectNumber != 0) {
                    let result = await roundDetails(dispatch, false);
                    dispatch(updateRoundDetails(result))
                    setInterval(async () => {
                        let result = await roundDetails(dispatch, false);
                        dispatch(updateRoundDetails(result))
                    }, 25000);
                }
            })
            .then(async () => {
                const result = await checkAddressWhiteListing(dispatch, false);
                // console.log(result);
                if (result.isAddressWhiteListingAllowed) {
                    dispatch(addressWhiteListing(result));
                    setTimeout(() => {
                        result.isAddressWhiteListed ? toastr.success("", result.message) : toastr.error("", result.message);
                    }, 5000);
                }
            })
            .catch(error => {
                console.log(error);
                toastr.warning("", error);
                dispatch(connectMetaskFailed(error));
            });
    }
};

export const sendTransaction = (transactionValue) => {
    return dispatch => {
        return InitiateTransaction(transactionValue)
            .then(data => {
                console.log(data);
                dispatch(transactionCompleted(data));
            })
            .catch(error => {
                console.log(error);
                dispatch(connectMetaskFailed(error));
            });
    }
}

export const fetchRoundDetails = () => {
    return dispatch => {
        return roundDetails()
            .then(data => {
                console.log(data);
                dispatch(updateRoundDetails(data))
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export const claimTokens = (id) => {
    return disptach => {
        return InitateClaimTokens(id)
            .then(data => {
                console.log(data);
                disptach(updateClaimedTokens(data))
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export const updateAccountDetails = (data) => ({
    type: actions.ACCOUNT_DETAILS,
    payload: {
        data
    }
});

export const addressWhiteListing = (data) => ({
    type: actions.ADDRESS_WHITELISTING,
    payload: {
        data
    }
});

export const transactionCompleted = (data) => ({
    type: actions.TRANSACTION_COMPLETED,
    payload: {
        data
    }
});

export const contributionRounds = (data) => ({
    type: actions.CONTRIBUTION_ROUNDS,
    payload: {
        data
    }
});

export const updateRoundDetails = (data) => ({
    type: actions.ROUND_DETAILS,
    payload: {
        data
    }
});

export const updateClaimedTokens = (data) => ({
    type: actions.CLAIM_TOKENS,
    payload: {
        data
    }
});

export const updateRouter = (data) => ({
    type: actions.ROUTER_VALIDATION,
    payload: {
        data
    }
});

export const toggleModal = () => (
    console.log('in'), {
        type: actions.TOGGLE_MODAL
    });

export const connectMetaskFailed = (error) => ({
    type: actions.ERROR,
    payload: {
        error
    }
});

export const disconnectMetamask = () => ({
    type: actions.DISCONNECT_METAMASK
});