// require('file-loader?name=[name].[ext]!./index.html');
require('./assets/icons/favicon.ico');
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import App from './App';
import store from './store';
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import "react-sweet-progress/lib/style.css";
import './index.css';

const app = (
    <React.StrictMode>
        <Provider store={store}>
            <App />
            <ReduxToastr
                timeOut={5000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-right"
                getState={(state) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                showCloseButton={true}
                closeOnToastrClick={true}
            />
        </Provider>
    </React.StrictMode >
);

ReactDOM.render(
    app,
    document.getElementById('root')
);