import { createStore, combineReducers, applyMiddleware } from 'redux';
import reducer from './reducers/reducer';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk';
import { reducer as toastrReducer } from 'react-redux-toastr'

const combineReducer = combineReducers({
    reducer,
    toastr: toastrReducer
});

const middelware = [thunk]
const store = createStore(
    combineReducer,
    composeWithDevTools(applyMiddleware(...middelware))
);

export default store;